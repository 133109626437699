<template>
  <div id="pop-alert" class="dialog" :class="{ on, ing }">
    <div class="pop-wrap type01">
      <div class="alert-tit">{{ title }}</div>
      <div class="alert-msg">
        {{ description }}
        <br />
        <span>{{ subDescription }}</span>
      </div>
      <div class="pop-btn-wrap">
        <button type="button" class="btn-gray" @click="onCancelClick">
          {{ cancelBtnText }}
        </button>
        <button type="button" class="btn-org" @click="onConfirmClick">
          {{ confirmBtnText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import popupMixin from 'Mixins/popup'

export default {
  mixins: [popupMixin],
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    subDescription: {
      type: String,
    },
    cancelBtnText: {
      type: String,
      default: '취소',
    },
    confirmBtnText: {
      type: String,
      default: '확인',
    },
  },
  methods: {
    onCancelCLick() {
      this.$emit('cancel')
    },
    onConfirmClick() {
      this.$emit('confirm')
    },
  },
}
</script>

<style></style>
